// Clevertap stuff
import { getCookie, resolveKeysInObjectsArrays, isEmpty, isUserSignedIn, isCtUser } from 'utils/browserHelper'
import ClevertapReact from './clevertap'

const CLEVERTAP_KEY = process.env.CT_PUBLIC_CLEVERTAP_KEY || ''

class Clevertap {
    constructor() {
        this.enabled = false
        if (CLEVERTAP_KEY) {
            this.enabled = true
            ClevertapReact.initialize(CLEVERTAP_KEY)
        }
    }

    event = (name, payload = {}) => {
        try {
            if (this.enabled) {
                const domain = resolveKeysInObjectsArrays(['location', 'hostname'], window)
                const ctAb = isEmpty(getCookie('ct-ab')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-ab')))
                payload.platform = 'DESKTOP'
                payload.domainName = domain || 'NA'
                payload.isCtLoginUser = isCtUser()
                payload.is_logged_in = isUserSignedIn() ? 'yes' : 'no'
                payload.fareFamily_abCookie = ctAb['ff']
                payload.showTable_abCookie = ctAb['fareFamily'] || 'N/A'
                payload.gh35_abCookie = ctAb['gh35']
                payload.mealImage_abCookie = ctAb['mealImage']
                payload.upgrade_abcookie = ctAb['ctUpgrade']
                payload.vasExp7 = ctAb['vasExp7'] || 'N/A'
                payload.vasExp6 = ctAb['vasExp6'] || 'N/A'
                payload.otpless = ctAb['otpless']
                payload.tk_home_abcookie = ctAb['tk_home']
                payload.tk_itin_abcookie = ctAb['tk_itin']
                payload.splrt = ctAb['splrt']
                payload.highRps = ctAb['highRps'] || "N/A"
                payload.gh60_abCookie = ctAb['gh60']
                payload.ptc_abcookie = ctAb['ptc_fare'] || 'N/A'
                payload.sc_right_rail_abcookie = ctAb['sc_right_rail']
                payload.Itin_RightRail_abcookie = ctAb['Itin_RightRail']
                payload.gh62_abCookie = ctAb['gh62']
                payload.gh66 = ctAb['gh66']
                payload.mediCancel_abCookie = ctAb['mediCancel'] || 'N/A'
                payload.login_cookie = ctAb['inlineLogin_nudge'] || 'N/A'
                payload.login_banner_cookie = ctAb['login_init'] || 'N/A'
                payload.h_exp17_abcookie = ctAb['h_exp17'] || 'N/A'
                payload.emiSrpDom_abCookie = ctAb['EMI_SRP_Dom']
                payload.emiSrpExp_abCookie = ctAb['EMI_SRP_Exp']
                payload.emiItinExp_abCookie = ctAb['EMI_Itin_Exp']
                payload.newHighRps_abcookie = ctAb['newHighRps'] || 'N/A'
                payload.axisLoyalty_abcookie = ctAb['axisLoyalty'] || 'N/A'
                setTimeout(() => {
                    ClevertapReact.event(name, payload)
                }, 300)
            }
        } catch (e) {
            console.error(e)
        }
    }

    profile = payload => {
        if (this.enabled) {
            ClevertapReact.profile(payload)
        }
    }

    logout = () => {
        if (this.enabled) {
            ClevertapReact.logout()
        }
    }
}

const ClevertapObject = new Clevertap()
export default ClevertapObject
